import { faUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Flex, Link } from "@chakra-ui/react";

import { ClassesEnum } from "@ts/enums/ClassesEnum.ts";

import { getTrackingId, isActiveUrl } from "@utils/utils";

interface Props {
    linkText: string;
    url: string;
    isExternal?: boolean;
    dataTrackingName: string;
}

const NavItem = ({ linkText, url, isExternal, dataTrackingName }: Props) => {
    // Checks whether the provided URL corresponds to the current page.
    // Test
    const isActive = url && !isExternal ? isActiveUrl(url) : false;
    console.log("isEx", isExternal, url, isActive);

    return (
        <>
            <Link
                href={url}
                isExternal={isExternal}
                fontWeight="600"
                color="gray.600"
                display="inline-flex"
                flexDirection="column"
                alignItems="center"
                transition="color 0.2s ease"
                textDecor="none"
                data-tracking-id={getTrackingId(
                    [ClassesEnum.Link],
                    dataTrackingName
                )}
                _after={{
                    content: "''",
                    backgroundColor: "burntYellow.500",
                    width: "2rem",
                    height: "3px",
                    borderRadius: "3px",
                    transformOrigin: "center",
                    transform: "scaleX(0)",
                    transition: "transform 0.2s ease"
                }}
                _hover={{
                    color: "gray.800",
                    _after: {
                        transform: "scaleX(1)"
                    }
                }}
                _activeLink={{
                    color: "gray.800",
                    _after: {
                        transform: "scaleX(1)",
                        backgroundColor: "burntYellow.500"
                    }
                }}
                aria-current={isActive ? "page" : undefined}
            >
                <Flex
                    as="span"
                    display="inline-flex"
                    alignItems="center"
                    gap="0.5rem"
                >
                    {linkText}
                    {isExternal && (
                        <FontAwesomeIcon
                            icon={faUpRightFromSquare}
                            fontSize={12}
                            data-testid="externalImg"
                        />
                    )}
                </Flex>
            </Link>
        </>
    );
};

export default NavItem;
