import { MouseEvent, useEffect } from "react";
import { Trans } from "react-i18next";

import useParentsProps from "@/hooks/useParentsProps.ts";
import useSettingsPageProps from "@/hooks/useSettingsPageProps.ts";
import { datadogLogs } from "@datadog/browser-logs";
import { datadogRum } from "@datadog/browser-rum";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useForm } from "@inertiajs/react";

import {
    Box,
    Flex,
    Image,
    Menu,
    MenuButton,
    MenuDivider,
    MenuItem,
    MenuList,
    Text
} from "@chakra-ui/react";

import logoSvg from "@assets/img/logo-black-and-white.svg";

import { truncateName } from "@utils/utils.ts";

import NavItem from "../NavItem/NavItem.tsx";
import MobileNav from "./MobileNav.tsx";

const ParentsNav = () => {
    const { post } = useForm({});

    const { parentUser } = useParentsProps();
    const hasPrimaryStudents: boolean = parentUser?.hasPrimaryStudents ?? true;

    const { parentsNav = [] } = useSettingsPageProps();

    useEffect(() => {
        if (parentUser) {
            const studentAuth0Usernames = parentUser.students
                ?.map(student => student.auth0_username)
                .join(", ");
            datadogRum.setUser({
                id: parentUser.id.toString(),
                name: parentUser.name,
                chargebeeId: parentUser.chargebeeId,
                students: studentAuth0Usernames
            });
            datadogLogs.setUser({
                id: parentUser.id.toString(),
                name: parentUser.name,
                chargebeeId: parentUser.chargebeeId,
                students: studentAuth0Usernames
            });
        }
    }, [parentUser]);

    const handleLogout = (event: MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();

        post(route("logout"), {
            replace: true
        });
    };

    return (
        <Flex
            height="4rem"
            borderBottom="1px"
            borderBottomColor="gray.300"
            px={10}
            py={4}
            bg="gray.50"
            justify="space-between"
            alignItems="center"
            wrap="wrap"
            w="100%"
        >
            <a href={route("parents.dashboard")}>
                <Image
                    src={logoSvg}
                    alt="Euka Logo"
                    pointerEvents="none"
                    userSelect="none"
                    width="74px"
                    height="32px"
                    data-testid="logoImg"
                />
            </a>
            {parentUser && parentUser.id !== 0 && (
                <>
                    <Flex display={{ base: "flex", md: "none" }}>
                        <MobileNav handleLogout={handleLogout} />
                    </Flex>

                    <Flex
                        gap="2rem"
                        alignItems="center"
                        marginInline="auto"
                        marginTop="3px"
                        display={{ base: "none", md: "flex" }}
                    >
                        {parentsNav.map(item => {
                            console.log("item", item);
                            return (
                                <NavItem
                                    key={item.id}
                                    url={
                                        item.isExternal
                                            ? "https://help.euka.edu.au"
                                            : route(item.route)
                                    }
                                    linkText={item.name}
                                    isExternal={item.isExternal}
                                    dataTrackingName={item.name}
                                />
                            );
                        })}
                    </Flex>
                </>
            )}
            {parentUser && parentUser.id !== 0 && (
                <Box display={{ base: "none", md: "flex" }}>
                    <Menu>
                        {({ isOpen }) => (
                            <>
                                <MenuButton
                                    cursor="pointer"
                                    title={parentUser && parentUser.displayName}
                                    textDecor="none"
                                    _hover={{
                                        textDecor: "none"
                                    }}
                                >
                                    <Text
                                        fontWeight={600}
                                        display="flex"
                                        gap={2}
                                    >
                                        {truncateName(
                                            parentUser && parentUser.displayName
                                        )}
                                        <Box
                                            as="span"
                                            transition="transform 0.2s ease"
                                            transform={
                                                isOpen
                                                    ? "rotate(180deg)"
                                                    : "rotate(0)"
                                            }
                                            transformOrigin="center"
                                            color="gray.600"
                                        >
                                            <FontAwesomeIcon
                                                icon={faChevronDown}
                                                fontSize={14}
                                                data-testid="chevronDownImg"
                                            />
                                        </Box>
                                    </Text>
                                </MenuButton>
                                <MenuList
                                    borderRadius="1rem"
                                    boxShadow="0px 4px 20px -2px rgba(50, 50, 71, 0.08), 0px 0px 1px 0px rgba(12, 26, 75, 0.10)"
                                    borderColor="gray.200"
                                >
                                    <MenuItem
                                        as="a"
                                        href={route("parents.account-details")}
                                        padding="0.5rem 1rem"
                                        fontWeight={600}
                                        data-testid="accountDetails"
                                    >
                                        <Trans>Account details</Trans>
                                    </MenuItem>
                                    {hasPrimaryStudents && (
                                        <MenuItem
                                            as="a"
                                            href={route(
                                                "parents.shared-access"
                                            )}
                                            padding="0.5rem 1rem"
                                            fontWeight={600}
                                            data-testid="sharedAccess"
                                        >
                                            <Trans>Shared access</Trans>
                                        </MenuItem>
                                    )}
                                    <MenuDivider marginInline={4} />
                                    {hasPrimaryStudents && (
                                        <MenuItem
                                            as="a"
                                            href={route("external.lms.home")}
                                            target="_blank"
                                            padding="0.5rem 1rem"
                                            data-testid="switchToStudentPortal"
                                        >
                                            <Trans>
                                                Switch to student portal
                                            </Trans>
                                        </MenuItem>
                                    )}
                                    <MenuItem
                                        padding="0.5rem 1rem"
                                        onClick={handleLogout}
                                        data-testid="logout"
                                    >
                                        <Trans>Logout</Trans>
                                    </MenuItem>
                                </MenuList>
                            </>
                        )}
                    </Menu>
                </Box>
            )}
        </Flex>
    );
};

export default ParentsNav;
